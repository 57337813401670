import React, { Suspense, useContext, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import * as Sentry from '@sentry/browser';
import { ThemeContext } from 'styled-components';

import { GoogleMapsProvider } from '@common/utils/hooks/google/useGoogleLoader';
import { useConversionAnalytics } from '@common/utils/hooks/useConversionAnalytics';
import useQueryParams from '@common/utils/hooks/useQueryParams';

import { BookingContextProvider } from './_common/context/BookingContext';
import { BrandingProvider } from './_common/context/BrandingContext';
import { ErrorBoundary } from './_common/ErrorBoundary';
import { LANGUAGES } from './_common/queries';
import { CenteredSpinner, SpinnerSizes } from './_common/Spinner';
import { useOneTimeToken } from './_common/utils/hooks/useOneTimeToken';
import useUpdateTranslations from './_common/utils/hooks/useUpdateTranslations';
import { getLocale, Locale, setLocale } from './_common/utils/locale';
import apolloClient from './global/graphql/apolloClient';
import GlobalStyles from './global/style';
import ThemeProvider from './global/style/ThemeProvider';
import PrivateRoute from './modules/booking/_common/PrivateRoute';
import Error from './modules/error';
import LoginModule from './modules/login';
import PageNotFound from './modules/notFound';
import UserModule from './modules/user';
import { LanguagesQuery } from './types/api';
import {
  BOOKING_PATH,
  LEGACY_SELF_SERVICE_PATH,
  SELF_SERVICE_PATH,
} from './utils/constants';

// https://reactjs.org/docs/code-splitting.html#error-boundaries
const AdminModule = React.lazy(() => import('./modules/admin'));
const BookingModule = React.lazy(() => import('./modules/booking'));
const ConfirmModule = React.lazy(() => import('./modules/confirm'));

const favicon = process.env.FAVICON;

const DefaultRoute = () => {
  const location = useLocation();

  return <Redirect to={{ pathname: BOOKING_PATH, search: location.search }} />;
};

const AppContainer = () => (
  <Suspense fallback={<CenteredSpinner size={SpinnerSizes.LARGE} />}>
    <HelmetProvider>
      <ApolloProvider client={apolloClient}>
        <BookingContextProvider>
          <ThemeProvider>
            <BrandingProvider>
              <ErrorBoundary>
                <GoogleMapsProvider>
                  <App />
                </GoogleMapsProvider>
              </ErrorBoundary>
            </BrandingProvider>
          </ThemeProvider>
        </BookingContextProvider>
      </ApolloProvider>
    </HelmetProvider>
  </Suspense>
);

const App = () => {
  const { id: contextId } = useQueryParams();
  const { name } = useContext(ThemeContext);
  const { data: languageData, loading } = useQuery<LanguagesQuery>(LANGUAGES);

  useUpdateTranslations();
  useOneTimeToken();
  useConversionAnalytics();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && contextId != null) {
      Sentry.configureScope((scope) => {
        scope.setTag('contextId', contextId);
      });
    }
  }, [contextId]);

  useEffect(() => {
    if (languageData !== undefined && languageData.languages.length > 0) {
      setLocale(
        (
          languageData?.languages.find((l) => l.default) ??
          languageData?.languages[0]
        ).locale as Locale,
      );
    }
  }, [languageData]);

  if (loading || getLocale() === null) {
    return <CenteredSpinner size={SpinnerSizes.LARGE} />;
  }

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <html lang={getLocale() || 'en'} />
        <title>{name}</title>
        <link href={favicon} rel="shortcut icon" type="image/x-icon" />
      </Helmet>
      <Switch>
        <PrivateRoute component={DefaultRoute} path="/" exact />
        <PrivateRoute component={Error} path="/error" exact />
        <PrivateRoute component={AdminModule} path="/admin" />
        <PrivateRoute component={ConfirmModule} path="/confirm" />
        <PrivateRoute path="/booking" render={() => <BookingModule />} />
        <PrivateRoute component={UserModule} path="/user" />
        <Route
          path="/confirmCallback"
          render={() => <ConfirmModule isSelfService />}
        />
        <Route path={SELF_SERVICE_PATH} render={() => <BookingModule />} />
        <Route
          component={() => <Redirect to={SELF_SERVICE_PATH} />}
          path={LEGACY_SELF_SERVICE_PATH}
          exact
        />
        <Route component={LoginModule} path="/login" />
        <Route component={PageNotFound} path="*" />
      </Switch>
    </>
  );
};

export default AppContainer;
